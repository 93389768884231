import { onUnmounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import router from '@/router'
import itemStoreModule from '@/views/apps/publications/itemStoreModule'

export default function controller() {
  const USER_APP_STORE_MODULE_NAME = 'app-publication'

  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })
  const windowHeight = ref(window.innerHeight)
  const tableHeight = ref(window.innerHeight - 250)
  window.addEventListener('resize', () => {
    windowHeight.value = window.innerHeight
  })
  watch(windowHeight, () => {
    tableHeight.value = windowHeight.value - 250
  })
  const blankItem = {
    id: null,
    title: null,
    sum_words: null,
    sum_pages: null,
    custom_pages: false,
    custom_price: false,
    public_price: null,
    sum_copies: store.state.app.settings.dafault_value_copies,
    discount_price: null,
    unit_price: null,
    transportation: null,
    receipt_date: null,
    response_date: null,
    proposition_date: null,
    source_id: null,
    author_id: null,
    genre_id: null,
    format_id: null,
    country_id: null,
    author: {
      id: null,
      ful_name: null,
      email: null,
      author_ref: '',
      phone: null,
      avatar: null,
      source_id: null,
      status_id: null,
      user_id: null,
      addresses: [],
      password: null,
      changePassword: true,
    },
  }
  const form = ref()
  const items = ref([])
  const item = ref({})
  const paymentMethods = ref([
    { id: 'CB CPT', name: 'CB CPT' },
    { id: 'CB 2', name: 'CB 2' },
    { id: 'CB 3', name: 'CB 3' },
    { id: 'PP CPT', name: 'PP CPT' },
    { id: 'PP 2', name: 'PP 2' },
    { id: 'PP 3', name: 'PP 3' },
    { id: 'PP 4', name: 'PP 4' },
    { id: 'PP 5', name: 'PP 5' },
    { id: 'PP 6', name: 'PP 6' },
    { id: 'PP 7', name: 'PP 7' },
    { id: 'PP 8', name: 'PP 8' },
    { id: 'PP 9', name: 'PP 9' },
    { id: 'PP 10', name: 'PP 10' },
    { id: 'PP 11', name: 'PP 11' },
    { id: 'PP 12', name: 'PP 12' },
    { id: 'PP 13', name: 'PP 13' },
    { id: 'PP 14', name: 'PP 14' },
    { id: 'PP 15', name: 'PP 15' },
    { id: 'VT CPT', name: 'VT CPT' },
    { id: 'VT 2', name: 'VT 2' },
    { id: 'VT 3', name: 'VT 3' },
    { id: 'VT 4', name: 'VT 4' },
    { id: 'VT 5', name: 'VT 5' },
    { id: 'WU CPT', name: 'WU CPT' },
    { id: 'WU 2', name: 'WU 2' },
    { id: 'WU 3', name: 'WU 3' },
    { id: 'CP CPT', name: 'CP CPT' },
    { id: 'CP 2', name: 'CP 2' },
    { id: 'CP 3', name: 'CP 3' },
    { id: 'null', name: 'Vide' },
  ])
  const blockeds = ref([
    { id: 'DD', label: 'Dossier décalé' },
    { id: 'Pmt', label: 'Paiement' },
    { id: 'NS', label: 'Non signé' },
    { id: 'FW', label: 'Fichier Word' },
    { id: 'BC', label: 'BC' },
    { id: 'OV', label: 'Ouvert' },
  ])
  const qualities = ref(
    [
      { id: 4, label: 'QAD', title: 'QAD - Qualité à determiner' },
      { id: 3, label: 'TQS ', title: 'TQS - Texte de Qualité Supérieur' },
      { id: 1, label: 'TQ', title: 'TQ - Texte de Qualité' },
      { id: 2, label: 'TM', title: 'TM - Texte Moyen' },
      { id: 0, label: 'TS', title: 'TS - Texte Simple' },
      { id: -1, label: 'TSP', title: 'TSP - Texte Simple Plus' },
    ],
  )
  const isColor = (value, action, colorId) => {
    if (!value) return {}

    if (value.length > 0 && value.filter(ele => action === ele.colomn_name).length > 0) {
      let records = []
      records = value.filter(ele => action === ele.colomn_name)

      /* item.value.color_id = records[0].color_id || null
      item.value.fore_color_id = records[0].fore_color_id || null */

      if (!records.length || !records[0].color_id) return {}

      return records[0] && records[0].color_id && records[0].color_id === colorId
    }
  }
  const signatureForm = ref({})
  const tableExtraColumns = [
    {
      align: 'center',
      text: '',
      value: 'is_reading',
      fixed: true,
      class: 'fixed-column 50px ',
      cellClass: 'fixed-column 50px ',
      width: '50px',
      type: 'check',
      trueValue: 1,
      falseValue: 0,
      url: 'paiement_is_reading',
      comment_column: 'paiement_is_reading',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideEdit: false,

      fields: [
        {
          type: 'checkbox', label: 'Lecture', value: 'is_reading', trueValue: 1, falseValue: 0,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        multiple: false,
        column: 'publications.is_reading',
        values: [
          { id: 1, name: '*' },
          { id: 'null', name: 'Vide' },
        ],
      },
    },
    {
      align: 'center',
      text: 'Dossier',
      value: 'id',
      fixed: true,
      class: 'fixed-column 100px l-50',
      cellClass: 'fixed-column 100px l-50 py-1',
      width: '100px',
      editable: true,
      info: 'Ajouter un commentaire sur le dossier ?',
      comment_reference: 'type',
      comments: 'publication_records',
      fields: [
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: 'Numero...', type: 'text' },
    },
    {
      align: 'center',
      text: 'Manuscrit',
      value: 'manuscript_id',
      fixed: true,
      class: 'fixed-column 50px l-150',
      cellClass: 'fixed-column 100px l-150  py-1',
      width: '100px',
      filter: { place_holder: 'Numero...', type: 'text' },
    },
    {
      text: 'Auteur',
      value: 'author.full_name',
      fixed: true,
      class: 'fixed-column l-250 b-right ',
      cellClass: 'fixed-column 300px l-250 b-right  py-1',
      width: '150px',
      filter: {
        place_holder: 'Nom...',
        type: 'text',
        columns: ['authors.full_name', 'authors.email'],
        join_table: 'authors',
        join_column: 'authors.id',
        local_column: 'publications.author_id',
      },
    },
  ]
  const tableCollectHeader = [
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      action: 'edit',
      sortable: false,
      class: 'fixed-column 50px ',
      cellClass: 'fixed-column 50px ',
      width: '50px',
    },
    {
      align: 'center',
      text: '',
      value: 'is_reading',
      fixed: true,
      class: 'fixed-column 50px l-50',
      cellClass: 'fixed-column 50px l-50',
      width: '50px',
      type: 'check',
      trueValue: 1,
      falseValue: 0,
      url: 'paiement_is_reading',
      comment_column: 'paiement_is_reading',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        multiple: false,
        column: 'publications.is_reading',
        values: [
          { id: 1, name: '*' },
          { id: 'null', name: 'Vide' },
        ],
      },
    },
    {
      align: 'center',
      text: 'Dossier',
      value: 'id',
      fixed: true,
      class: 'fixed-column 50px l-100',
      cellClass: 'fixed-column 50px l-100 py-1',
      width: '100px',
      editable: true,
      info: 'Ajouter un commentaire sur le dossier ?',
      comment_reference: 'type',
      comments: 'publication_records',
      fields: [
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: 'Numero...', type: 'text' },
    },
    {
      align: 'center',
      text: 'Manuscrit',
      value: 'manuscript_id',
      fixed: true,
      class: 'fixed-column 50px l-200',
      cellClass: 'fixed-column 100px l-200  py-1',
      width: '100px',
      filter: { place_holder: 'Numero...', type: 'text' },
    },
    {
      text: 'Auteur',
      value: 'author.full_name',
      fixed: true,
      class: 'fixed-column l-300 b-right ',
      cellClass: 'fixed-column 300px l-300 b-right  py-1',
      width: '150px',
      filter: {
        place_holder: 'Nom...',
        type: 'text',
        columns: ['authors.full_name', 'authors.email'],
        join_table: 'authors',
        join_column: 'authors.id',
        local_column: 'publications.author_id',
      },
    },
  ]
  const tableCommonColumns = [
    ...tableExtraColumns,
    {
      text: 'Date de fin de conception',
      value: 'completion_date',
      align: 'center',
      cellClass: 'text-center',
      url: 'signature_completion_date',
      comment_column: 'signature_completion_date',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      info: 'Confirmez-vous de vouloir changer la date de conception ?',
      fields: [
        {
          type: 'date',
          label: 'Date de fin de conception',
          value: 'completion_date',
          required: false,
          noLimit: true,
        },

        /* {
          type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' }, */
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true, noLimit: true,
      },
      filters: [
        {
          key: 'completion_date',
          text: 'Date de fin de conception',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
          noLimit: true,
          column: 'publications.completion_date',
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_completion_date',
          column: 'publications.completion_date',
        },
      ],
    },
    {
      text: 'Période de publication',
      value: 'publishing_period',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
      filters: [
        {
          key: 'publishing_period',
          text: 'Période de publication',
          place_holder: 'Date1 - Date2',
          type: 'date',
          month: true,
          menu: false,
          range: true,
          column: 'publications.completion_date',
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publishing_period',
          column: 'publishing_period',
        },
      ],
    },
    {
      text: 'Titre',
      value: 'title',
      align: 'center',
      width: '200px',
      filter: {
        place_holder: '',
        type: 'text',

        /*  columns: ['manuscripts.title'],
          join_table: 'manuscripts',
          join_column: 'manuscripts.id',
          local_column: 'publications.manuscript_id', */
      },
    },
    {
      text: 'ISBN',
      value: 'isbn',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
    },
  ]
  const tableCommonColumnsReport = [
    ...tableExtraColumns,
    {
      text: 'Date de fin de conception',
      value: 'completion_date',
      align: 'center',
      cellClass: 'text-center',
      url: 'signature_completion_date',
      comment_column: 'signature_completion_date',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      info: 'Confirmez-vous de vouloir changer la date de conception ?',
      fields: [
        {
          type: 'date',
          label: 'Date de fin de conception',
          value: 'completion_date',
          required: false,
          noLimit: true,
        },

        /* {
          type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' }, */
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true, noLimit: true,
      },
      filters: [
        {
          key: 'completion_date',
          text: 'Date de fin de conception',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
          noLimit: true,
          column: 'publications.completion_date',
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_completion_date',
          column: 'publications.completion_date',
        },
      ],
    },
    {
      text: 'Période de publication',
      value: 'publishing_period',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
      filters: [
        {
          key: 'publishing_period',
          text: 'Période de publication',
          place_holder: 'Date1 - Date2',
          type: 'date',
          month: true,
          menu: false,
          range: true,
          column: 'publications.completion_date',
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publishing_period',
          column: 'publications.completion_date',
        },
      ],
    },
    {
      text: 'Genre',
      value: 'genre.label',
      align: 'center',
      type: 'text',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      colors: 'publication_colors',
      comments: 'publication_records',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Genre" ?',
      fields: [
        {
          type: 'select',
          label: 'Genre',
          value: 'genre_id',
          required: false,
          item_value: 'id',
          item_text: 'label',
          items: store.state.app.genres,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: [],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'publications.genre_id',
        join_table: 'genres',
        join_column: 'genres.id',
        local_column: 'publications.genre_id',
      },
    },
    {
      text: 'Titre',
      value: 'title',
      align: 'center',
      width: '200px',
      filter: {
        place_holder: '',
        type: 'text',

        /*  columns: ['manuscripts.title'],
          join_table: 'manuscripts',
          join_column: 'manuscripts.id',
          local_column: 'publications.manuscript_id', */
      },
    },
    {
      text: 'ISBN',
      value: 'isbn',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
    },
  ]
  const tableCollectColumns = [
    ...tableCollectHeader,
    {
      text: 'Date de fin de conception',
      value: 'completion_date',
      align: 'center',
      cellClass: 'text-center',
      type: 'date',
      url: 'signature_completion_date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      editable: true,
      info: 'Confirmer la modification de la date de fin de conception ?',
      fields: [
        {
          type: 'date', label: 'Date de fin de conception', value: 'completion_date', menu: false,
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true, noLimit: true,
      },
      filters: [
        {
          key: 'completion_date',
          text: 'Date de fin de conception',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
          noLimit: true,
          column: 'publications.completion_date',
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_completion_date',
          column: 'publications.completion_date',
          noLimit: true,
        },
      ],
    },
    {
      text: 'Période de publication',
      value: 'publishing_period',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
      filters: [
        {
          key: 'publishing_period',
          text: 'Période de publication',
          place_holder: 'Date1 - Date2',
          type: 'date',
          month: true,
          menu: false,
          range: true,
          column: 'publications.completion_date',
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publishing_period',
          column: 'publishing_period',
        },
      ],
    },
    {
      text: 'Titre',
      value: 'title',
      align: 'center',
      width: '200px',
      filter: {
        place_holder: '',
        type: 'text',
      },
    },
    {
      text: 'ISBN',
      value: 'isbn',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
    },
  ]
  const commonPublicationColumns = [
    {
      text: 'Titre',
      value: 'title',
      align: 'center',
      type: 'text',
      width: '200px',
      filter: {
        place_holder: '',
        type: 'text',
      },
    },
    {
      text: 'ISBN',
      value: 'isbn',
      align: 'center',
      width: '100px',
      editable: false,
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Date signature',
      value: 'signature_date',
      align: 'center',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Date signature" ?',
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          required: true,
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'signature_date',
          text: 'Date signature',
          place_holder: 'Date1 - Date2',
          type: 'date',
          month: true,
          menu: false,
          range: true,
          column: 'signature_date',
          noLimit: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)).filter(ele => ele.type !== 'visual_idea'),
          label: 'color_name',
          id: 'id',
          multiple: false,
          key: 'publication_colors.colomn_name',

          /* columns: 'publication_colors.color_id', */
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'signature_date',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_signature_date',
          column: 'signature_date',
        },
      ],
    },
    {
      text: 'DA papier',
      value: 'nb_da_paper',
      align: 'center',
      type: 'text',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "DA papier" ?',
      fields: [
        {
          type: 'number', label: 'DA papier', value: 'nb_da_paper', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'DA num',
      value: 'nb_da_num',
      align: 'center',
      type: 'text',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "DA num" ?',
      fields: [
        {
          type: 'number', label: 'DA num', value: 'nb_da_num', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Nb ex',
      value: 'nb_copies',
      align: 'center',
      type: 'text',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      colors: 'publication_colors',
      comments: 'publication_records',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Nb ex" ?',
      fields: [
        {
          type: 'number', label: 'Nb ex', value: 'nb_copies', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Format',
      value: 'format.name',
      align: 'center',
      type: 'text',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      colors: 'publication_colors',
      comments: 'publication_records',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Format" ?',
      fields: [
        {
          type: 'select',
          label: 'Format',
          value: 'format_id',
          required: false,
          item_value: 'id',
          item_text: 'name',
          items: store.state.app.formats.filter(ele => ele.format_type === 'Publication'),
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.formats.filter(ele => ele.format_type === 'Publication'),
        label: 'name',
        id: 'id',
        multiple: true,
        column: 'publications.format_id',
        join_table: 'formats',
        join_column: 'formats.id',
        local_column: 'publications.format_id',
      },
    },

    // {
    //   text: 'Frais de port',
    //   value: 'shipping_costs',
    //   align: 'center',
    //   type: 'text',
    //   color_reference: 'colomn_name',
    //   comment_reference: 'type',
    //   colors: 'publication_colors',
    //   comments: 'publication_records',
    //   editable: true,
    //   hideEdit: true,
    //   info: 'Confirmer le traitement de "Frais de port" ?',
    //   fields: [
    //     {
    //       type: 'number', label: 'Frais de port', value: 'shipping_costs', required: false,
    //     },
    //     {
    //       type: 'color',
    //       label: 'Couleur de fond',
    //       value: 'color_id',
    //       item_value: 'id',
    //       item_text: 'color_name',
    //       items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
    //     },
    //     { type: 'textarea', label: 'Commentaire', value: 'comment' },
    //   ],
    //   filter: { place_holder: '', type: 'text' },
    // },
    {
      text: 'Nb de pages',
      value: 'sum_pages',

      // url: 'publication_sum_pages',
      align: 'center',
      type: 'text',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      colors: 'publication_colors',
      comments: 'publication_records',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Nb de pages" ?',
      fields: [
        // {
        //   type: 'number', label: 'Nb de pages', value: 'sum_pages', required: false,
        // },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Prix',
      value: 'price',
      align: 'center',
      class: 'font-weight-bold',
      cellClass: 'font-weight-bold title',
      type: 'text',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      colors: 'publication_colors',
      comments: 'publication_records',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Prix" ?',
      fields: [
        {
          type: 'number', label: 'Prix', value: 'price', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'USD',
      value: 'price_usd',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'AUD',
      value: 'price_aud',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'GBP',
      value: 'price_gbp',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'CFA',
      value: 'price_cfa',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Prix Public',
      value: 'manuscript.public_price',
      align: 'center',
      filter: {
        place_holder: '',
        type: 'number',

        columns: ['manuscripts.public_price'],
        join_table: 'manuscripts',
        join_column: 'manuscripts.id',
        local_column: 'publications.manuscript_id',
      },
    },
    {
      text: 'Dos',
      value: 'dos',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Largeur',
      value: 'book_with',
      align: 'center',
      type: 'text',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      colors: 'publication_colors',
      comments: 'publication_records',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Largeur" ?',
      fields: [
        {
          type: 'text', label: 'Largeur', value: 'book_with', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Montant',
      value: 'amount',
      align: 'center',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      colors: 'publication_colors',
      comments: 'publication_records',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Montant" ?',
      fields: [
        {
          type: 'number', label: 'Montant', value: 'amount', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Genre',
      value: 'genre.label',
      align: 'center',
      type: 'text',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      colors: 'publication_colors',
      comments: 'publication_records',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Genre" ?',
      fields: [
        {
          type: 'select',
          label: 'Genre',
          value: 'genre_id',
          required: false,
          item_value: 'id',
          item_text: 'label',
          items: store.state.app.genres,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: [],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'publications.genre_id',
        join_table: 'genres',
        join_column: 'genres.id',
        local_column: 'publications.genre_id',
      },
    },
    {
      text: 'Assistant communication',
      value: 'communication.assistant.code',
      align: 'center',
      action: 'assistant',
      sortable: false,
      filter: {
        place_holder: 'CODE',
        type: 'select',
        values: [],
        label: 'code',
        id: 'id',
        multiple: true,
        column: 'communications.assistant_id',
        join_table: 'users',
        key: 'users.id',
        join_column: 'communications.assistant_id',
        local_column: 'users.id',
      },
    },

    // {
    //   text: 'Prix d\'origine',
    //   value: 'original_price',
    //   align: 'center',
    //   color_reference: 'colomn_name',
    //   comment_reference: 'type',
    //   colors: 'publication_colors',
    //   comments: 'publication_records',
    //   editable: true,
    //   hideEdit: true,
    //   info: 'Confirmer le traitement de "Prix d\'origine" ?',
    //   fields: [
    //     {
    //       type: 'number', label: 'Prix d\'origine', value: 'original_price', required: false,
    //     },
    //     {
    //       type: 'color',
    //       label: 'Couleur de fond',
    //       value: 'color_id',
    //       item_value: 'id',
    //       item_text: 'color_name',
    //       items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
    //     },
    //     { type: 'textarea', label: 'Commentaire', value: 'comment' },
    //   ],
    //   filter: { place_holder: '', type: 'text' },
    // },
    {
      text: 'Période de publication',
      value: 'supervision_period',
      align: 'center',
      filters: [
        {
          key: 'general_supervision',
          text: 'Période de publication',
          place_holder: 'Date1 - Date2',
          type: 'date',
          month: true,
          menu: false,
          range: true,
          column: 'publications.completion_date',
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: null, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_general_supervision',
          column: 'general_supervision',
        },
      ],
    },
  ]
  const tableColumns = [
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
      class: 'fixed-column 50px ',
      cellClass: 'fixed-column 50px ',
      width: '50px',
    },
    {
      align: 'center',
      text: '',
      value: 'is_reading',
      fixed: true,
      class: 'fixed-column 50px l-50',
      cellClass: 'fixed-column 50px  l-50',
      width: '50px',
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        multiple: false,
        column: 'publications.is_reading',
        values: [
          { id: 1, name: '*' },
          { id: 'null', name: 'Vide' },
        ],
      },
    },
    {
      align: 'center',
      text: 'Dossier',
      value: 'id',
      fixed: true,
      class: 'fixed-column 50px l-100',
      cellClass: 'fixed-column l-100 py-1',
      editable: true,
      width: '100px',
      info: 'Ajouter un commentaire sur le dossier ?',
      comment_reference: 'type',
      comments: 'publication_records',
      fields: [
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: 'Numero...', type: 'text' },
      filters: [
        {
          key: 'id',
          text: 'Dossier',
          place_holder: 'Dossier',
          type: 'range',
          min: 0,
          max: 15000,
          step: 1,
          column: 'publications.id',
          noLimit: false,
          value: [],
        },

        /*  {
            text: 'Condition',
            place_holder: 'Condition',
            type: 'select',
            is_condition: true,
            values: [
              { id: 0, label: 'Est vide' },
              { id: 1, label: 'N\'est pas vide' },
            ],
            label: 'label',
            id: 'id',
            multiple: false,
            key: 'publication_errors_before_correction',
            column: 'id',
          }, */
      ],
    },
    {
      align: 'center',
      text: 'Manuscrit',
      value: 'manuscript_id',
      fixed: true,
      class: 'fixed-column 50px l-200',
      cellClass: 'fixed-column 50px l-200  py-1',
      width: '100px',
      filter: { place_holder: 'Numero...', type: 'text' },
    },
    {
      text: 'Auteur',
      value: 'author.full_name',
      fixed: true,
      class: 'fixed-column l-300 b-right ',
      cellClass: 'fixed-column 200px l-300 b-right  py-1',
      width: '150px',
      filter: {
        place_holder: 'Nom...',
        type: 'text',
        columns: ['authors.full_name', 'authors.email'],
        join_table: 'authors',
        join_column: 'authors.id',
        local_column: 'publications.author_id',
      },
    },
    ...commonPublicationColumns,
  ]

  const tableColumnsAuthorView = [
    {
      align: 'center',
      text: '',
      value: 'is_reading',
      fixed: true,
      class: 'fixed-column 50px ',
      cellClass: 'fixed-column 50px ',
      width: '50px',
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        multiple: false,
        column: 'publications.is_reading',
        values: [
          { id: 1, name: '*' },
        ],
      },
    },
    {
      align: 'center',
      text: 'Dossier',
      value: 'id',
      fixed: true,
      class: 'fixed-column 50px l-50',
      cellClass: 'fixed-column 50px l-50 py-1',
      width: '50px',
      editable: true,
      info: 'Ajouter un commentaire sur le dossier ?',
      fields: [
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: 'Numero...', type: 'text' },
    },
    {
      align: 'center',
      text: 'Manuscrit',
      value: 'manuscript_id',
      fixed: true,
      class: 'fixed-column 50px l-100',
      cellClass: 'fixed-column 100px l-100  py-1',
      width: '50px',
      filter: { place_holder: 'Numero...', type: 'text' },
    },
    ...commonPublicationColumns,
  ]
  const signatureTableColumnsForReport = [
    {
      text: 'Accord de publication',
      value: 'agreement_date',
      align: 'center',
      action: 'send',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      info: 'Confirmez-vous qu\'un accord de publication a été trouvé ?',
      fields: [
        {
          type: 'date', label: 'Accord de publication', value: 'agreement_date', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'agreement_date',
          text: 'Accord de publication',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)).filter(ele => ele.type !== 'visual_idea'),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          /* columns: 'publication_colors.color_id', */
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'agreement_date',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_agreement_date',
          column: 'agreement_date',
        },
      ],
    },
    {
      text: 'Date envoi',
      value: 'send_date',
      align: 'center',
      action: 'send',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      info: 'Confirmez-vous qu\'une demande de signature a été envoyée ?',
      fields: [
        {
          type: 'date', label: 'Date envoi', value: 'send_date', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (td && th.value && td.signature_date) {
            td.denieds = td.denieds || {}
            td.denieds[th.value] = true
            td.classes = td.classes || {}
            td.classes[th.value] = { 'w-100': true, 'h-100': true, 'add-padding': true }
            td.classes[th.value][th.blockBg || 'bg-grey'] = true
          } else {

          }
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'send_date',
          text: 'Date envoi',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)).filter(ele => ele.type !== 'visual_idea'),
          label: 'color_name',
          id: 'id',
          multiple: false,
          key: 'publication_colors.colomn_name',

          /* columns: 'publication_colors.color_id', */
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'send_date',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_send_date',
          column: 'send_date',
        },
      ],
    },
    {
      text: 'Date relance',
      value: 'resend_date',
      align: 'center',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      info: 'Confirmez-vous de relancer la demande de signature ?',
      fields: [
        {
          type: 'date', label: 'Date relance', value: 'resend_date', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (td && th.value && !td.send_date) {
            td.denieds = td.denieds || {}
            td.denieds[th.value] = true
            td.classes = td.classes || {}
            td.classes[th.value] = { 'w-100': true, 'h-100': true, 'add-padding': true }
            td.classes[th.value][th.blockBg || 'bg-grey'] = true
          } else if (td && th.value && td.signature_date) {
            td.denieds = td.denieds || {}
            td.denieds[th.value] = true
            td.classes = td.classes || {}
            td.classes[th.value] = { 'w-100': true, 'h-100': true, 'add-padding': true }
            td.classes[th.value][th.blockBg || 'bg-grey'] = true
          } else {

          }
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'resend_date',
          text: 'Date relance',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)).filter(ele => ele.type !== 'visual_idea'),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          /* columns: 'publication_colors.color_id', */
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'resend_date',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_resend_date',
          column: 'resend_date',
        },
      ],
    },
    {
      text: 'Date signature',
      value: 'signature_date',
      url: 'signature',
      comment_column: 'signature',
      align: 'center',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      info: 'Confirmez-vous de marquer comme signé ?',
      fields: [
        {
          type: 'select',
          label: 'Statut',
          value: 'status_id',
          required: true,
          item_value: 'id',
          item_text: 'name',
          items: store.state.app.statuses.filter(ele => ele.status_type === 'publication'),
        },
        {
          type: 'date', label: 'Date signature', value: 'signature_date', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (td && th.value && !td.send_date) {
            td.denieds = td.denieds || {}
            td.denieds[th.value] = true
            td.classes = td.classes || {}
            td.classes[th.value] = { 'w-100': true, 'h-100': true/* 'add-padding': true */ }
            td.classes[th.value][th.blockBg || 'bg-grey'] = true
          } else {

          }
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'signature_date',
          text: 'Date signature',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)).filter(ele => ele.type !== 'visual_idea'),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          /* columns: 'publication_colors.color_id', */
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'signature_date',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_signature_date',
          column: 'signature_date',
        },
      ],
    },
    {
      text: 'Statut',
      value: 'status',
      align: 'center',
      queryColumn: 'status_id',
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.statuses.filter(ele => ele.status_type === 'publication'),
        label: 'name',
        id: 'id',
        multiple: true,
        column: 'publications.status_id',
        join_table: 'statuses',
        join_column: 'statuses.id',
        local_column: 'publications.status_id',
      },
    },
  ]
  const signatureTableColumns = [
    ...tableCommonColumns,
    {
      text: 'Cas particulier',
      value: 'manuscript.particular_case.label',
      align: 'center',
      action: 'manuscript.particular_case.label',
      filter: {
        place_holder: '',
        type: 'select',

        // values: [{ id: 'yes', label: 'Oui' }, { id: 'no', label: 'Non' }],
        values: store.getters['particular_cases'],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'manuscripts.particular_case_id',
        join_table: 'manuscripts',
        join_column: 'manuscripts.id',
        // local_column: 'manuscripts.particular_case_id',
      },
    },
    {
      text: 'Assistant',
      value: 'assistant.code',
      align: 'center',
      action: 'assistant',
      filter: {
        place_holder: 'CODE', type: 'select', values: [], label: 'code', id: 'id', multiple: true,
      },
    },
    ...signatureTableColumnsForReport,
  ]

  const collectTableColumnsForReport = [
    {
      text: 'Cas particulier',
      value: 'manuscript.particular_case.label',
      align: 'center',
      action: 'manuscript.particular_case.label',
      filter: {
        place_holder: '',
        type: 'select',

        // values: [{ id: 'yes', label: 'Oui' }, { id: 'no', label: 'Non' }],
        values: store.getters['particularCase/particular_cases'],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'manuscripts.particular_case_id',
        join_table: 'manuscripts',
        join_column: 'manuscripts.id',
        // local_column: 'manuscripts.particular_case_id',
      },
    },
    {
      text: 'Mise à jour BRAME',
      value: 'brame_update',
      align: 'center',
      url: 'publication_brame_update',
      comment_column: 'publication_brame_update',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la mise à jour BRAME a été faite ?',
      fields: [
        {
          type: 'date', label: 'Mise à jour BRAME', value: 'brame_update', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'brame_update',
          text: 'Mise à jour BRAME',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'brame_update',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_brame_update',
          column: 'brame_update',
        },
      ],
    },
    {
      text: 'Demande d\'éléments',
      value: 'request_elements',
      url: 'collecte_elements',
      comment_column: 'collecte_elements',
      align: 'center',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que les éléments ont été demandés ?',
      fields: [
        {
          type: 'date', label: 'Demande d\'éléments', value: 'request_elements', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'request_elements_menu',
        range: true,
      },
      filters: [
        {
          key: 'request_elements',
          text: 'Demande d\'éléments',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)).filter(ele => ele.type !== 'visual_idea'),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'request_elements',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_request_elements',
          column: 'request_elements',
        },
      ],
    },
    {
      text: 'Photo auteur',
      value: 'author_avatar',
      url: 'collecte_author_avatar',
      comment_column: 'collecte_author_avatar',
      align: 'center',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le fichier word a été collecté ?',
      fields: [
        {
          type: 'date', label: 'Photo auteur', value: 'author_avatar', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'author_avatar_menu',
        range: true,
      },
      filters: [
        {
          key: 'author_avatar',
          text: 'Photo auteur',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'author_avatar',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_author_avatar',
          column: 'author_avatar',
        },
      ],
    },
    {
      text: 'Fichier Word',
      value: 'word_file',
      align: 'center',
      url: 'collecte_word_file',
      comment_column: 'collecte_word_file',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le fichier word a été collecté ?',
      fields: [
        {
          type: 'date', label: 'Fichier Word', value: 'word_file', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'word_file_menu',
        range: true,
      },
      filters: [
        {
          key: 'word_file',
          text: 'Fichier Word',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'word_file',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_word_file',
          column: 'publications.word_file',
        },
      ],
    },
    {
      text: 'Biographie courte',
      value: 'short_biography',
      align: 'center',
      url: 'collecte_short_biography',
      comment_column: 'collecte_short_biography',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la courte biographie a été collectée ?',
      fields: [
        {
          type: 'date', label: 'Biographie courte', value: 'short_biography', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'short_biography_menu',
        range: true,
      },
      filters: [
        {
          key: 'short_biography',
          text: 'Biographie courte',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'short_biography',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_short_biography',
          column: 'short_biography',
        },
      ],
    },
    {
      text: 'Résumé',
      value: 'resume',
      align: 'center',
      url: 'collecte_resume',
      comment_column: 'collecte_resume',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le résumé a été collecté ?',
      fields: [
        {
          type: 'date', label: 'Résumé', value: 'resume', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'word_file_menu',
        range: true,
      },
      filters: [
        {
          key: 'resume',
          text: 'Résumé',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'resume',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_resume',
          column: 'resume',
        },
      ],
    },
    {
      text: 'Adresse et téléphone',
      value: 'address_phone',
      align: 'center',
      url: 'collecte_address_phone',
      comment_column: 'collecte_address_phone',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que l\'adresse et le téléphone ont été collectés ?',
      fields: [
        {
          type: 'date', label: 'Adresse et téléphone', value: 'address_phone', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'word_file_menu',
        range: true,
      },
      filters: [
        {
          key: 'address_phone',
          text: 'Adresse et téléphone',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'address_phone',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_address_phone',
          column: 'address_phone',
        },
      ],
    },
    {
      text: 'Visuel - idée',
      value: 'visual_idea',
      align: 'center',
      url: 'collecte_visual_idea',
      comment_column: 'collecte_visual_idea',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le visuel-idée a été collecté ?',
      fields: [
        {
          type: 'date', label: 'Visuel - idée', value: 'visual_idea', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de texte',
          value: 'fore_color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'visual_idea_menu',
        range: true,
      },
      filters: [
        {
          key: 'visual_idea',
          text: 'Visuel - idée',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur du texte',
          place_holder: 'Couleur du texte',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          is_visual_idea: true,
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'visual_idea',
              single: true,
            },
            {
              name: 'publication_colors.fore_color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'visual_idea',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_visual_idea',
          column: 'visual_idea',
        },
      ],
    },
    {
      text: 'Présence Réseaux Sociaux',
      value: 'compte_facebook',
      align: 'center',
      url: 'collecte_compte_facebook',
      comment_column: 'compte_facebook',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la présence sur les réseaux sociaux a été collectée ?',
      fields: [
        {
          type: 'date', label: 'Présence Réseaux Sociaux', value: 'compte_facebook', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'word_file_menu',
        range: true,
      },
      filters: [
        {
          key: 'compte_facebook',
          text: 'Compte Facebook',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'compte_facebook',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_compte_facebook',
          column: 'compte_facebook',
        },
      ],
    },
    {
      text: 'Facebook',
      value: 'facebook',
      align: 'center',
      url: 'collecte_facebook',
      type: 'select',
      items: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
      select_label: 'label',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que Facebook a été traité ?',
      fields: [
        {
          type: 'select',
          label: 'Facebook',
          value: 'facebook',
          required: false,
          items: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
          item_text: 'label',
          item_value: 'id',
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filters: [
        {
          text: 'Facebook',
          column: 'facebook',
          key: 'facebook',
          place_holder: 'Facebook',
          type: 'select',
          values: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
          label: 'label',
          id: 'id',
          multiple: false,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_facebook',
          column: 'facebook',
        },
      ],
    },
    {
      text: 'Instagram',
      value: 'instagram',
      align: 'center',
      url: 'collecte_instagram',
      type: 'select',
      items: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
      select_label: 'label',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que Instagram a été traité ?',
      fields: [
        {
          type: 'select',
          label: 'Instagram',
          value: 'instagram',
          required: false,
          items: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
          item_text: 'label',
          item_value: 'id',
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filters: [
        {
          text: 'Instagram',
          column: 'instagram',
          key: 'instagram',
          place_holder: 'Instagram',
          type: 'select',
          values: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
          label: 'label',
          id: 'id',
          multiple: false,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_instagram',
          column: 'instagram',
        },
      ],
    },
    {
      text: 'Meilleure phrase',
      value: 'best_sentence',
      align: 'center',
      url: 'collecte_best_sentence',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la meilleure phrase a été recueillie ?',
      fields: [
        {
          type: 'date', label: 'Meilleure phrase', value: 'best_sentence', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'best_sentence_menu',
        range: true,
      },
      filters: [
        {
          key: 'best_sentence',
          text: 'Meilleure phrase',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'best_sentence',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_best_sentence',
          column: 'best_sentence',
        },
      ],
    },
    {
      text: 'Dossier bloqué',
      value: 'file_blocked',
      align: 'center',
      url: 'collecte_file_blocked',
      blockBg: 'bg-grey',
      type: 'text',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      comment_column: 'collecte_file_blocked',
      editable: true,
      hideValue: false,
      info: 'Veuillez entrer les informations ci-dessous',
      fields: [
        {
          type: 'select',
          label: 'Statut dossier',
          value: 'file_blocked',
          required: false,
          item_value: 'id',
          item_text: 'label',
          items: blockeds.value,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        column: 'publications.file_blocked',
        values: blockeds.value,
        label: 'label',
        id: 'id',
        multiple: true,
      },
    },

    {
      text: 'Dossier rapide',
      value: 'quick_file',
      align: 'center',
      url: 'collecte_quick_file',
      blockBg: 'bg-grey',
      type: 'check',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      trueValue: 1,
      falseValue: 0,
      editable: true,
      hideValue: false,
      info: 'Veuillez entrer les informations ci-dessous',
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        column: 'publications.quick_file',
        values: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    {
      text: 'Nb de fautes avant correction',
      value: 'errors_before_correction',
      align: 'center',
      url: 'collecte_errors_before_correction',
      blockBg: 'bg-grey',
      type: 'text',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      comment_column: 'collecte_errors_before_correction',
      editable: true,
      hideValue: false,
      info: 'Veuillez indiquer le nombre d\'erreurs',
      fields: [
        {
          type: 'number',
          label: 'Nb de fautes avant correction',
          value: 'errors_before_correction',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filters: [
        {
          key: 'errors_before_correction',
          text: 'Nb de fautes avant correction',
          place_holder: 'Nb de fautes avant correction',
          type: 'range',
          min: 0,
          max: 1000,
          step: 1,
          column: 'errors_before_correction',
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_errors_before_correction',
          column: 'errors_before_correction',
        },
      ],
    },
    {
      text: 'Nb de fautes par page',
      value: 'error_per_page',
      align: 'center',
      url: 'collecte_error_per_page',
      comment_column: 'collecte_error_per_page',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      blockBg: 'bg-grey',
      type: 'text',
      editable: true,
      hideValue: false,
      hideEdit: true,
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'error_per_page',
          text: 'Nb de fautes par page',
          place_holder: 'Nb de fautes par page',
          type: 'range',
          min: 0,
          max: 1000,
          step: 1,
          column: 'error_per_page',
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_error_per_page',
          column: 'error_per_page',
        },
      ],
    },
    {
      text: 'Nb de pages',
      value: 'sum_pages',
      align: 'center',
      type: 'text',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      colors: 'publication_colors',
      comments: 'publication_records',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Nb de pages" ?',
      fields: [
        {
          type: 'text', label: 'Nb de pages', value: 'sum_pages', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Montant',
      value: 'amount',
      align: 'center',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      colors: 'publication_colors',
      comments: 'publication_records',
      editable: true,
      hideEdit: true,
      info: 'Confirmer le traitement de "Montant" ?',
      fields: [
        {
          type: 'number', label: 'Montant', value: 'amount', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'BNF',
      value: 'bnf_form',
      align: 'center',
      url: 'paiement_bnf_form',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      comment_column: 'paiement_bnf_form',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le formulaire BNF a été fait ?',
      fields: [
        {
          type: 'date', label: 'BNF formulaire', value: 'bnf_form', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (td.publishing_bod) {
            if (td.publication_colors && td.publication_colors.length > 0) {
              const recordsT = td.publication_colors.filter(ele => ele.colomn_name === 'publishing_bod').sort((a, b) => b.id - a.id)
              if (recordsT.length === 0 || !recordsT[0].color || !recordsT[0].color.id || recordsT[0].color_id != (store.state.app.settings.publication_bod_status || 1)) {

              } else {
                return true
              }
            }
          }

          td.denieds = td.denieds || {}
          td.denieds[th.value] = true
          td.classes = td.classes || {}
          td.classes[th.value] = { 'w-100': true, 'h-100': true/* 'add-padding': true */ }
          td.classes[th.value][th.blockBg || 'bg-grey'] = true
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'bnf_form',
          text: 'BNF',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'bnf_form',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_bnf_form',
          column: 'bnf_form',
        },
      ],
    },
  ]
  const collectTableColumns = [
    ...tableCollectColumns,
    {
      text: 'Assistant',
      value: 'assistant.code',
      align: 'center',
      action: 'assistant',
      filter: {
        place_holder: 'CODE', type: 'select', values: [], label: 'code', id: 'id', multiple: true,
      },
    },
    ...collectTableColumnsForReport,
    {
      text: 'Publication BOD',
      value: 'publishing_bod',
      align: 'center',
      url: 'publication_publishing_bod',
      comment_column: 'publication_publishing_bod',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      info: 'Confirmez-vous que la publication BOD a été faite ?',
      fields: [
        {
          type: 'date', label: 'Publication BOD', value: 'publishing_bod', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'publishing_bod',
          text: 'Publication BOD',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'publishing_bod',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publishing_bod',
          column: 'publishing_bod',
        },
      ],
    },
  ]

  const textTableColumnsForReport = [
    {
      text: 'Assistant',
      value: 'assistant.code',
      align: 'center',
      action: 'assistant',
      filter: {
        place_holder: 'CODE', type: 'select', values: [], label: 'code', id: 'id', multiple: true,
      },
    },
    {
      text: 'Prise de contact',
      value: 'prise_contact',
      align: 'center',
      url: 'text_prise_contact',
      comment_column: 'text_prise_contact',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la prise de contact avec l\'auteur a été faite ?',
      fields: [
        {
          type: 'date',
          label: 'Date de la prise de contact',
          value: 'prise_contact',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'prise_contact',
          text: 'Prise de contact',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'prise_contact',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_prise_contact',
          column: 'prise_contact',
        },
      ],
    },
    {
      text: 'Mise en page',
      value: 'page_layout',
      align: 'center',
      action: 'page',
      url: 'text_page_layout',
      comment_column: 'text_page_layout',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la mise en page a été faite ?',
      fields: [
        {
          type: 'date', label: 'Mise en page', value: 'page_layout', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'pay_layout_menu',
        range: true,
      },
      filters: [
        {
          key: 'page_layout',
          text: 'Mise en page',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'page_layout',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_page_layout',
          column: 'page_layout',
        },
      ],
    },
    {
      text: 'Correction 1',
      value: 'correction1',
      align: 'center',
      url: 'text_correction1',
      comment_column: 'text_correction1',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la correction 1 a été faite ?',
      fields: [
        {
          type: 'date', label: 'Correction 1', value: 'correction1', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'pay_layout_menu',
        range: true,
      },
      filters: [
        {
          key: 'correction1',
          text: 'Correction 1',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'correction1',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_correction1',
          column: 'correction1',
        },
      ],
    },
    {
      text: 'Correction 2',
      value: 'correction2',
      align: 'center',
      url: 'text_correction2',
      comment_column: 'text_correction2',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la correction 2 a été faite ?',
      fields: [
        {
          type: 'date', label: 'Correction 2', value: 'correction2', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'correction2',
          text: 'Correction 2',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'correction2',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_correction2',
          column: 'correction2',
        },
      ],
    },
    {
      text: 'Envoi BAT texte',
      value: 'send_text_bat',
      align: 'center',
      url: 'text_send_text_bat',
      comment_column: 'text_send_text_bat',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que l\'envoi BAT texte a été fait ?',
      fields: [
        {
          type: 'date', label: 'Envoi BAT texte', value: 'send_text_bat', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'send_text_bat',
          text: 'Envoi BAT texte',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'send_text_bat',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_send_text_bat',
          column: 'send_text_bat',
        },
      ],
    },
    {
      text: 'BAT texte',
      value: 'text_bat',
      align: 'center',
      url: 'text_text_bat',
      comment_column: 'text_text_bat',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le BAT texte été fait?',
      fields: [
        {
          type: 'date', label: 'Envoi BAT texte', value: 'text_bat', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'text_bat',
          text: 'BAT texte',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'text_bat',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_text_bat',
          column: 'text_bat',
        },
      ],
    },
    {
      text: 'Qualité',
      value: 'is_quality_text',
      align: 'center',
      url: 'text_quality_text',
      comment_column: 'text_quality_text',
      blockBg: 'bg-grey',
      type: 'select',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      trueValue: 'TQ',
      falseValue: 'TS',
      selected_label: 'label',
      items: qualities.value,
      info: 'Confirmez-vous que la vérification de la qualité a été faite ?',
      fields: [
        {
          type: 'date', label: 'Date traitement', value: 'quality_text', required: false,
        },
        {
          type: 'select',
          label: 'Qualité',
          value: 'is_quality_text',
          required: false,
          items: qualities.value,
          item_text: 'title',
          item_value: 'id',
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filters: [
        {
          text: 'Qualité du texte',
          column: 'publications.is_quality_text',
          key: 'is_quality_text',
          place_holder: 'Qualité du texte',
          type: 'select',
          values: [...qualities.value, {
            id: 'Null',
            label: 'Vide',
            title: 'Vide',
          }],
          label: 'title',
          id: 'id',
          multiple: false,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'is_quality_text',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'is_quality_text',
          column: 'publications.is_quality_text',
        },
      ],
    },
  ]
  const textTableColumns = [
    ...tableCommonColumns,
    {
      text: 'Cas particulier',
      value: 'manuscript.particular_case.label',
      align: 'center',
      action: 'manuscript.particular_case.label',
      filter: {
        place_holder: '',
        type: 'select',

        // values: [{ id: 'yes', label: 'Oui' }, { id: 'no', label: 'Non' }],
        values: store.getters['particularCase/particular_cases'],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'manuscripts.particular_case_id',
        join_table: 'manuscripts',
        join_column: 'manuscripts.id',
        // local_column: 'manuscripts.particular_case_id',
      },
    },
    {
      text: 'Ancien auteur',
      value: 'author.old_author',
      align: 'center',
      cellClass: 'text-center',
      action: 'author.old_author',
      viewPermission: 'author.old_author',
      // editPermission: 'edit_author.old_author',
      // colors: 'manuscript_colors',
      // comments: 'manuscript_comments',
      editable: false,
      // info: 'Confirmer le traitement de "Ancien auteur" ?',
      // fields: [
      //   {
      //     type: 'select',
      //     label: 'Ancien auteur',
      //     value: 'author.old_author',
      //     required: false,
      //     items: [{id: 1, label: 'Oui'}, {id: null, label: 'Non'}],
      //     item_text: 'label',
      //     item_value: 'id',
      //   },
      // ],
      filter: {
        place_holder: '',
        type: 'select',
        values: [{id: 1, label: 'Anciens auteurs'}, {id: 0, label: 'Nouveaux auteurs'}],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    {
      text: 'Genre',
      value: 'genre.label',
      align: 'center',
      cellClass: 'text-center',
      action: 'genre.label',
      viewPermission: 'genre',
      editPermission: 'edit_genre',
      colors: 'publication_colors',
      comments: 'publication_comments',
      editable: false,
      filters: [
        {
          place_holder: '',
          type: 'select',
          values: store.state.app.genres,
          label: 'label',
          id: 'id',
          multiple: true,
          key: 'publication_genre_id',
          column: 'publications.genre_id',
          join_table: 'genres',
          join_column: 'genres.id',
          local_column: 'publications.genre_id',
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_genre_id',
          column: 'genre_id',
        },
      ],
    },
    {
      text: 'Assistant',
      value: 'assistant.code',
      align: 'center',
      action: 'assistant',
      filter: {
        place_holder: 'CODE', type: 'select', values: [], label: 'code', id: 'id', multiple: true,
      },
    },
    {
      text: 'Nb de fautes avant correction',
      value: 'errors_before_correction',
      align: 'center',
      url: 'collecte_errors_before_correction',
      blockBg: 'bg-grey',
      type: 'text',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      comment_column: 'collecte_errors_before_correction',
      editable: false,
      hideEdit: true,
      hideValue: false,
      info: 'Veuillez indiquer le nombre d\'erreurs',
      fields: [
        {
          type: 'text',
          label: 'Nb de fautes avant correction',
          value: 'errors_before_correction',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filters: [
        {
          key: 'errors_before_correction',
          text: 'Nb de fautes avant correction',
          place_holder: 'Nb de fautes avant correction',
          type: 'range',
          min: 0,
          max: 1000,
          step: 1,
          column: 'errors_before_correction',
          noLimit: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'errors_before_correction',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'collecte_errors_before_correction',
          column: 'errors_before_correction',
        },
      ],
    },
    {
      text: 'Nb de fautes par page',
      value: 'error_per_page',
      align: 'center',
      url: 'collecte_error_per_page',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      blockBg: 'bg-grey',
      type: 'text',
      editable: false,
      hideEdit: true,
      hideValue: false,
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'error_per_page',
          text: 'Nb de fautes avant correction',
          place_holder: 'Nb de fautes avant correction',
          type: 'range',
          min: 0,
          max: 1000,
          step: 1,
          column: 'error_per_page',
          noLimit: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'error_per_page',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'collecte_error_per_page',
          column: 'error_per_page',
        },
      ],
    },

    {
      text: 'Dossier bloqué',
      value: 'conception_locked_folder',
      align: 'center',
      url: 'text_conception_locked_folder',
      blockBg: 'bg-grey',
      type: 'text',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      comment_column: 'conception_locked_folder',
      editable: true,
      hideValue: false,
      info: 'Veuillez entrer les informations ci-dessous',
      fields: [
        {
          type: 'select',
          label: 'Statut dossier',
          value: 'conception_locked_folder',
          required: false,
          item_value: 'id',
          item_text: 'label',
          items: blockeds.value,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        column: 'publications.conception_locked_folder',
        values: blockeds.value,
        label: 'label',
        id: 'id',
        multiple: true,
      },
    },
    {
      text: 'Dossier rapide',
      value: 'quick_file',
      align: 'center',
      url: 'collecte_quick_file',
      blockBg: 'bg-grey',
      type: 'check',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      trueValue: 1,
      falseValue: 0,
      editable: true,
      hideValue: false,
      info: 'Veuillez entrer les informations ci-dessous',
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        column: 'publications.quick_file',
        values: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    {
      text: 'Prise de contact',
      value: 'prise_contact',
      align: 'center',
      url: 'text_prise_contact',
      comment_column: 'text_prise_contact',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la prise de contact a été faite ?',
      fields: [
        {
          type: 'date',
          label: 'Date de la prise de contact',
          value: 'prise_contact',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'prise_contact',
          text: 'Prise de contact',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'prise_contact',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_prise_contact',
          column: 'prise_contact',
        },
      ],
    },
    {
      text: 'Mise en page',
      value: 'page_layout',
      align: 'center',
      action: 'page',
      url: 'text_page_layout',
      comment_column: 'text_page_layout',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la mise en page a été faite ?',
      fields: [
        {
          type: 'date', label: 'Mise en page', value: 'page_layout', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'pay_layout_menu',
        range: true,
      },
      filters: [
        {
          key: 'page_layout',
          text: 'Mise en page',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'page_layout',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_page_layout',
          column: 'page_layout',
        },
      ],
    },
    {
      text: 'Correction 1',
      value: 'correction1',
      align: 'center',
      url: 'text_correction1',
      comment_column: 'text_correction1',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la correction 1 a été faite ?',
      fields: [
        {
          type: 'date', label: 'Correction 1', value: 'correction1', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        ref: 'pay_layout_menu',
        range: true,
      },
      filters: [
        {
          key: 'correction1',
          text: 'Correction 1',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'correction1',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_correction1',
          column: 'correction1',
        },
      ],
    },
    {
      text: 'Correction 2',
      value: 'correction2',
      align: 'center',
      url: 'text_correction2',
      comment_column: 'text_correction2',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la correction 2 a été faite ?',
      fields: [
        {
          type: 'date', label: 'Correction 2', value: 'correction2', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'correction2',
          text: 'Correction 2',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'correction2',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_correction2',
          column: 'correction2',
        },
      ],
    },
    {
      text: 'Envoi BAT texte',
      value: 'send_text_bat',
      align: 'center',
      url: 'text_send_text_bat',
      comment_column: 'text_send_text_bat',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que l\'envoi BAT texte a été fait ?',
      fields: [
        {
          type: 'date', label: 'Envoi BAT texte', value: 'send_text_bat', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'send_text_bat',
          text: 'Envoi BAT texte',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'send_text_bat',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_send_text_bat',
          column: 'send_text_bat',
        },
      ],
    },
    {
      text: 'BAT texte',
      value: 'text_bat',
      align: 'center',
      url: 'text_text_bat',
      comment_column: 'text_text_bat',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la BAT texte été fait?',
      fields: [
        {
          type: 'date', label: 'Envoi BAT texte', value: 'text_bat', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (!td.send_text_bat) {
            td.denieds = td.denieds || {}
            td.denieds[th.value] = true
            td.classes = td.classes || {}
            td.classes[th.value] = { 'w-100': true, 'h-100': true/* 'add-padding': true */ }
            td.classes[th.value][th.blockBg || 'bg-grey'] = true
          }
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'text_bat',
          text: 'BAT texte',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'text_bat',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_text_bat',
          column: 'text_bat',
        },
      ],
    },
    {
      text: 'Etat du travail',
      value: 'is_text_bat_finished',
      align: 'center',
      url: 'text_is_text_bat_finished',
      comment_column: 'text_is_text_bat_finished',
      blockBg: 'bg-grey',
      type: 'check',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      hideValue: false,
      filter: {
        column: 'is_text_bat_finished',
        place_holder: 'Etat du travail',
        type: 'select',
        values: [{ id: 1, label: 'Terminé' }, { id: 0, label: 'En cours' }],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    {
      text: 'Qualité',
      value: 'is_quality_text',
      align: 'center',
      url: 'text_quality_text',
      comment_column: 'text_quality_text',
      blockBg: 'bg-grey',
      type: 'select',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      trueValue: 'TQ',
      falseValue: 'TS',
      selected_label: 'label',
      items: qualities.value,
      info: 'Confirmez-vous que la vérification de la qualité a été faite ?',
      fields: [
        /* {
          type: 'date', label: 'Date traitement', value: 'quality_text', required: false,
        }, */
        {
          type: 'select',
          label: 'Qualité',
          value: 'is_quality_text',
          required: false,
          items: qualities.value,
          item_text: 'title',
          item_value: 'id',
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filters: [
        {
          text: 'Qualité du texte',
          column: 'publications.is_quality_text',
          key: 'is_quality_text',
          place_holder: 'Qualité du texte',
          type: 'select',
          values: [...qualities.value, {
            id: 'Null',
            label: 'Vide',
            title: 'Vide',
          }],
          label: 'title',
          id: 'id',
          multiple: false,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'is_quality_text',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'is_quality_text',
          column: 'publications.is_quality_text',
        },
      ],
    },
    {
      text: 'Texte de couleur',
      value: 'texte_de_couleur',
      align: 'center',
      type: 'check',
      comment_reference: 'type',
      comments: 'publication_records',
      trueValue: 1,
      falseValue: 0,
      editable: true,
      info: 'Définir comme texte de couleur ?',
      fields: [
        { type: 'checkbox', label: 'Texte de couleur', value: 'texte_de_couleur' },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          td.classes = td.classes || {}
          td.classes[th.value] = { 'w-100': true, 'h-100': true, 'add-padding': true }
        },
      ],
      filter: {
        column: 'texte_de_couleur',
        place_holder: 'Texte de couleur',
        type: 'select',
        values: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },

  ]

  const coverTableColumnsForReport = [
    {
      text: 'Couverture',
      value: 'cover',
      align: 'center',
      url: 'cover_cover',
      comment_column: 'cover_cover',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la couverture a été faite ?',
      fields: [
        {
          type: 'date', label: 'Couverture', value: 'cover', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'cover',
          text: 'Couverture',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'cover',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_cover',
          column: 'cover',
        },
      ],
    },
    {
      text: 'Vérif. 4e',
      value: 'verif4',
      align: 'center',
      url: 'cover_verif4',
      comment_column: 'cover_verif4',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la vérification de la 4e de couverture a été faite ?',
      fields: [
        {
          type: 'date', label: 'Vérif. 4e', value: 'verif4', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'verif4',
          text: 'Vérif. 4e',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'verif4',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_verif4',
          column: 'verif4',
        },
      ],
    },
    {
      text: 'Envoi projet',
      value: 'project_sending',
      align: 'center',
      url: 'cover_project_sending',
      comment_column: 'cover_project_sending',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le projet a été envoyé ?',
      fields: [
        {
          type: 'date', label: 'Couverture', value: 'project_sending', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'project_sending',
          text: 'Envoi projet',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'project_sending',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_project_sending',
          column: 'project_sending',
        },
      ],
    },
    {
      text: 'BAT couverture',
      value: 'bat_cover',
      align: 'center',
      url: 'cover_bat_cover',
      comment_column: 'cover_bat_cover',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le BAT couverture a été fait ?',
      fields: [
        {
          type: 'date', label: 'Couverture', value: 'bat_cover', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'bat_cover',
          text: 'BAT couverture',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'bat_cover',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_bat_cover',
          column: 'bat_cover',
        },
      ],
    },
    {
      text: 'Etat du travail',
      value: 'is_text_cover_finished',
      align: 'center',
      url: 'cover_is_text_cover_finished',
      comment_column: 'cover_is_text_cover_finished',
      blockBg: 'bg-grey',
      type: 'check',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      hideValue: false,
      info: 'Confirmez-vous que la vérification de la qualité a été faite ?',
      filter: {
        column: 'is_text_cover_finished',
        place_holder: 'Qualité du texte',
        type: 'select',
        values: [{ id: 1, label: 'Terminé' }, { id: 0, label: 'En cours' }],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    {
      text: 'Vérif gestionnaire',
      value: 'manager_verify',
      align: 'center',
      url: 'cover_manager_verify',
      comment_column: 'cover_manager_verify',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la vérification a été faite ?',
      fields: [
        {
          type: 'date', label: 'Couverture', value: 'manager_verify', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (td.bat_cover && td.text_bat
            && isColor(td.publication_colors, 'bat_cover', store.state.app.settings.green_color_id)
            && isColor(td.publication_colors, 'text_bat', store.state.app.settings.green_color_id)) {
            return true
          }
          td.denieds = td.denieds || {}
          td.denieds[th.value] = true
          td.classes = td.classes || {}
          td.classes[th.value] = { 'w-100': true, 'h-100': true/* 'add-padding': true */ }
          td.classes[th.value][th.blockBg || 'bg-grey'] = true
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'manager_verify',
          text: 'Vérif gestionnaire',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'manager_verify',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_manager_verify',
          column: 'manager_verify',
        },
      ],

    },
  ]
  const coverTableColumns = [
    ...tableCommonColumns,
    {
      text: 'Cas particulier',
      value: 'manuscript.particular_case.label',
      align: 'center',
      action: 'manuscript.particular_case.label',
      filter: {
        place_holder: '',
        type: 'select',

        // values: [{ id: 'yes', label: 'Oui' }, { id: 'no', label: 'Non' }],
        values: store.getters['particularCase/particular_cases'],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'manuscripts.particular_case_id',
        join_table: 'manuscripts',
        join_column: 'manuscripts.id',
        // local_column: 'manuscripts.particular_case_id',
      },
    },
    {
      text: 'Ancien auteur',
      value: 'author.old_author',
      align: 'center',
      cellClass: 'text-center',
      action: 'author.old_author',
      viewPermission: 'author.old_author',
      // editPermission: 'edit_author.old_author',
      // colors: 'manuscript_colors',
      // comments: 'manuscript_comments',
      editable: false,
      // info: 'Confirmer le traitement de "Ancien auteur" ?',
      // fields: [
      //   {
      //     type: 'select',
      //     label: 'Ancien auteur',
      //     value: 'author.old_author',
      //     required: false,
      //     items: [{id: 1, label: 'Oui'}, {id: null, label: 'Non'}],
      //     item_text: 'label',
      //     item_value: 'id',
      //   },
      // ],
      filter: {
        place_holder: '',
        type: 'select',
        values: [{id: 1, label: 'Anciens auteurs'}, {id: 0, label: 'Nouveaux auteurs'}],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    {
      text: 'Assistant',
      value: 'cover_assistant.code',
      align: 'center',
      action: 'assistant',
      filter: {
        place_holder: 'CODE', type: 'select', values: [], label: 'code', id: 'id', multiple: true,
      },
    },
    ...coverTableColumnsForReport,

  ]

  const supervisionTableColumnsForReport = [
    {
      text: 'Supervision',
      value: 'general_supervision',
      align: 'center',
      url: 'cover_general_supervision',
      comment_column: 'cover_general_supervision',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la vérification a été faite ?',
      fields: [
        {
          type: 'date', label: 'Supervision', value: 'general_supervision', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (td.manager_verify
            && isColor(td.publication_colors, 'manager_verify', store.state.app.settings.green_color_id)
          ) {
            return true
          }
          td.denieds = td.denieds || {}
          td.denieds[th.value] = true
          td.classes = td.classes || {}
          td.classes[th.value] = { 'w-100': true, 'h-100': true/* 'add-padding': true */ }
          td.classes[th.value][th.blockBg || 'bg-grey'] = true
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'general_supervision',
          text: 'Supervision',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
          noLimit: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'general_supervision',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_general_supervision',
          column: 'general_supervision',
        },
      ],
    },
    {
      text: 'Période de supervision',
      value: 'supervision_period',
      align: 'center',
      url: 'supervision_period',
      comment_column: 'supervision_period',
      blockBg: 'bg-grey',
      type: 'text',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      hideEdit: true,
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        type: 'text',
      },
      filters: [
        {
          key: 'supervision_period',
          text: 'Période de supervision',
          place_holder: 'Date1 - Date2',
          type: 'date',
          month: true,
          menu: false,
          range: true,
          column: 'general_supervision',
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_supervision_period',
          column: 'supervision_period',
        },
      ],
    },
  ]
  const supervisionTableColumns = [
    ...tableCommonColumns,
    {
      text: 'Cas particulier',
      value: 'manuscript.particular_case.label',
      align: 'center',
      action: 'manuscript.particular_case.label',
      filter: {
        place_holder: '',
        type: 'select',

        // values: [{ id: 'yes', label: 'Oui' }, { id: 'no', label: 'Non' }],
        values: store.getters['particularCase/particular_cases'],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'manuscripts.particular_case_id',
        join_table: 'manuscripts',
        join_column: 'manuscripts.id',
        // local_column: 'manuscripts.particular_case_id',
      },
    },
    {
      text: 'Assistant',
      value: 'assistant.code',
      align: 'center',
      action: 'assistant',
      filter: {
        place_holder: 'CODE', type: 'select', values: [], label: 'code', id: 'id', multiple: true,
      },
    },
    {
      text: 'Texte de couleur',
      value: 'texte_de_couleur',
      align: 'center',
      type: 'check',
      comment_reference: 'type',
      comments: 'publication_records',
      trueValue: 1,
      falseValue: 0,
      editable: true,
      info: 'Définir comme texte de couleur ?',
      fields: [
        { type: 'checkbox', label: 'Texte de couleur', value: 'texte_de_couleur' },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          td.classes = td.classes || {}
          td.classes[th.value] = { 'w-100': true, 'h-100': true, 'add-padding': true }
        },
      ],
      filter: {
        column: 'texte_de_couleur',
        place_holder: 'Texte de couleur',
        type: 'select',
        values: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    {
      text: 'Vérif gestionnaire',
      value: 'manager_verify',
      align: 'center',
      url: 'cover_manager_verify',
      comment_column: 'cover_manager_verify',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la vérification a été faite ?',
      fields: [
        {
          type: 'date', label: 'Couverture', value: 'manager_verify', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (td.bat_cover && td.text_bat
            && isColor(td.publication_colors, 'bat_cover', store.state.app.settings.green_color_id)
            && isColor(td.publication_colors, 'text_bat', store.state.app.settings.green_color_id)) {
            return true
          }
          td.denieds = td.denieds || {}
          td.denieds[th.value] = true
          td.classes = td.classes || {}
          td.classes[th.value] = { 'w-100': true, 'h-100': true/* 'add-padding': true */ }
          td.classes[th.value][th.blockBg || 'bg-grey'] = true
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'manager_verify',
          text: 'Vérif gestionnaire',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'manager_verify',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_manager_verify',
          column: 'manager_verify',
        },
      ],

    },
    ...supervisionTableColumnsForReport,
  ]

  const publicationTableColumnsForReport = [
    {
      text: 'Assistant Publication',
      value: 'assistant_publication.code',
      align: 'center',
      action: 'assistant',
      filter: {
        place_holder: 'CODE', type: 'select', values: [], label: 'code', id: 'id', multiple: true,
      },
    },
    {
      text: 'Création compte auteur',
      value: 'author_account_creation',
      align: 'center',
      url: 'publication_author_account_creation',
      comment_column: 'publication_author_account_creation',
      blockBg: 'bg-grey',
      type: 'text',
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      hideEdit: false,
      info: 'Confirmez-vous que le compte a été créé ?',
      fields: [
        {
          type: 'text',
          label: 'Création compte auteur',
          value: 'author_account_creation',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: '', type: 'text',
      },
    },
    {
      text: 'Site LLB',
      value: 'llb_website',
      align: 'center',
      url: 'publication_llb_website',
      comment_column: 'publication_llb_website',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que l\'information a été publiée sur le site Web ?',
      fields: [
        {
          type: 'date', label: 'Site LLB', value: 'llb_website', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'llb_website',
          text: 'Site LLB',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'llb_website',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_llb_website',
          column: 'llb_website',
        },
      ],
    },
    {
      text: 'Publication BOD',
      value: 'publishing_bod',
      align: 'center',
      url: 'publication_publishing_bod',
      comment_column: 'publication_publishing_bod',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la publication BOD a été faite ?',
      fields: [
        {
          type: 'date', label: 'Publication BOD', value: 'publishing_bod', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'publishing_bod',
          text: 'Publication BOD',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'publishing_bod',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publishing_bod',
          column: 'publishing_bod',
        },
      ],
    },
    {
      text: 'Publication Amazon',
      value: 'publication_amazon',
      align: 'center',
      url: 'publication_publication_amazon',
      comment_column: 'publication_publication_amazon',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la publication sur Amazon a été faite ?',
      fields: [
        {
          type: 'date', label: 'Publication Amazon', value: 'publication_amazon', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'publication_amazon',
          text: 'Publication Amazon',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'publication_amazon',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publication_amazon',
          column: 'publication_amazon',
        },
      ],
    },
    {
      text: 'Publication Laballery',
      value: 'publication_laballery',
      align: 'center',
      url: 'publication_publication_laballery',
      comment_column: 'publication_publication_laballery',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la publication Laballery a été faite ?',
      fields: [
        {
          type: 'date',
          label: 'Publication Laballery',
          value: 'publication_laballery',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'publication_laballery',
          text: 'Publication Laballery',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'publication_laballery',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publication_laballery',
          column: 'publication_laballery',
        },
      ],
    },
    {
      text: 'Publication Robot',
      value: 'final_bod',
      align: 'center',
      url: 'publication_final_bod',
      comment_column: 'publication_final_bod',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la Publication Robot a été faite ?',
      fields: [
        {
          type: 'date', label: 'Publication Robot', value: 'final_bod', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'final_bod',
          text: 'Publication Robot',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'final_bod',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_final_bod',
          column: 'final_bod',
        },
      ],
    },
    {
      text: 'Publication Dilicom',
      value: 'publication_dilicom',
      align: 'center',
      url: 'publication_publication_dilicom',
      comment_column: 'publication_publication_dilicom',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la Publication Dilicom a été faite ?',
      fields: [
        {
          type: 'date', label: 'Publication Dilicom', value: 'publication_dilicom', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'publication_dilicom',
          text: 'Publication Dilicom',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'publication_dilicom',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publication_dilicom',
          column: 'publication_dilicom',
        },
      ],
    },
    {
      text: 'Cas spécial Babelio',
      value: 'cas_special_babelio',
      align: 'center',
      cellClass: 'text-center',
      url: 'publication_cas_special_babelio',
      type: 'select',
      select_label: 'label',
      items: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
      editable: true,
      filter: {
        column: 'cas_special_babelio',
        place_holder: 'Autorisation spéciale',
        type: 'select',
        values: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
        label: 'label',
        id: 'id',
        multiple: false,
      },
      info: 'Changement de l\'autorisation spéciale',
      fields: [
        {
          type: 'select',
          items: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
          item_text: 'label',
          item_value: 'id',
          value: 'cas_special_babelio',
        },
      ],
    },
    {
      text: 'Babelio',
      value: 'babelio',
      align: 'center',
      url: 'publication_babelio',
      comment_column: 'publication_babelio',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la Babelio a été faite ?',
      fields: [
        {
          type: 'date',
          label: 'Babelio',
          value: 'babelio',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (td.cas_special_babelio || td.author_payment && isColor(td.publication_colors, 'author_payment', store.state.app.settings.green_color_id)) {
            return true
          }
          td.denieds = td.denieds || {}
          td.denieds[th.value] = true
          td.classes = td.classes || {}
          td.classes[th.value] = { 'w-100': true, 'h-100': true/* 'add-padding': true */ }
          td.classes[th.value][th.blockBg || 'bg-grey'] = true
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'babelio',
          text: 'Babelio',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'babelio',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_babelio',
          column: 'babelio',
        },
      ],
    },
    {
      text: 'Publication Primento',
      value: 'publication_primento',
      align: 'center',
      url: 'publication_publication_primento',
      comment_column: 'publication_publication_primento',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la publication Primento a été faite ?',
      fields: [
        {
          type: 'date',
          label: 'Publication Primento',
          value: 'publication_primento',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (td.author_payment && isColor(td.publication_colors, 'author_payment', store.state.app.settings.green_color_id)) {
            return true
          }
          td.denieds = td.denieds || {}
          td.denieds[th.value] = true
          td.classes = td.classes || {}
          td.classes[th.value] = { 'w-100': true, 'h-100': true/* 'add-padding': true */ }
          td.classes[th.value][th.blockBg || 'bg-grey'] = true
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'publication_primento',
          text: 'Publication Primento',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'publication_primento',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publication_primento',
          column: 'publication_primento',
        },
      ],
    },
    {
      text: 'Mail de Publication',
      value: 'publication_mail',
      align: 'center',
      url: 'publication_publication_mail',
      comment_column: 'publication_publication_mail',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le mail de publication a été envoyé ?',
      fields: [
        {
          type: 'date', label: 'Mail de Publication', value: 'publication_mail', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'publication_mail',
          text: 'Mail de Publication',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'publication_mail',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publication_mail',
          column: 'publication_mail',
        },
      ],
    },
    {
      text: 'Republication BOD',
      value: 'republish_bod',
      align: 'center',
      url: 'publication_republish_bod',
      comment_column: 'publication_republish_bod',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la Republication BOD a été fait ?',
      fields: [
        {
          type: 'date', label: 'Republication BOD', value: 'republish_bod', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filters: [
        {
          key: 'republish_bod',
          text: 'Republication BOD',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'republish_bod',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_republish_bod',
          column: 'republish_bod',
        },
      ],
    },
    {
      text: 'Republication Laballery',
      value: 'republication_laballery',
      align: 'center',
      url: 'publication_republication_laballery',
      comment_column: 'publication_republication_laballery',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la Republication Laballery a été fait ?',
      fields: [
        {
          type: 'date',
          label: 'Republication Laballery',
          value: 'republication_laballery',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filters: [
        {
          key: 'republication_laballery',
          text: 'Republication Laballery',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'republication_laballery',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_republication_laballery',
          column: 'republication_laballery',
        },
      ],
    },
  ]
  const publicationTableColumns = [
    ...tableCommonColumns.filter(ele => ele.value !== 'publishing_period'),
    {
      text: 'Période de supervision',
      value: 'supervision_period',
      align: 'center',
      url: 'supervision_period',
      comment_column: 'supervision_period',
      blockBg: 'bg-grey',
      type: 'text',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      hideValue: false,

      /* filter: {
        type: 'text',
      }, */
      filters: [
        {
          key: 'supervision_period',
          text: 'Période de supervision',
          place_holder: 'Date1 - Date2',
          type: 'date',
          month: true,
          menu: false,
          range: true,
          column: 'general_supervision',
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_supervision_period',
          column: 'supervision_period',
        },
      ],
    },
    {
      text: 'Texte de couleur',
      value: 'texte_de_couleur',
      align: 'center',
      type: 'check',
      comment_reference: 'type',
      comments: 'publication_records',
      trueValue: 1,
      falseValue: 0,
      editable: false,
      info: 'Ajouter un Texte de couleur ?',
      fields: [
        { type: 'checkbox', label: 'Texte de couleur', value: 'texte_de_couleur' },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          td.classes = td.classes || {}
          td.classes[th.value] = { 'w-100': true, 'h-100': true, 'add-padding': true }
        },
      ],
      filter: {
        column: 'texte_de_couleur',
        place_holder: 'Texte de couleur',
        type: 'select',
        values: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
        label: 'label',
        id: 'id',
        multiple: false,
      },
    },
    ...publicationTableColumnsForReport,
    {
      text: 'Paiement auteur',
      value: 'author_payment',
      align: 'center',
      url: 'paiement_author_payment',
      comment_column: 'paiement_author_payment',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      hideValue: false,
      info: 'Confirmez-vous que le paiement auteur a été reçu ?',

      /* fields: [
        {
          type: 'date', label: 'Paiement auteur', value: 'author_payment', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ], */
      /*  functions: [
        (th, td) => {

        },
      ], */
      cellClass: 'text-center',
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'author_payment',
          text: 'Paiement auteur',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'author_payment',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_author_payment',
          column: 'author_payment',
        },
      ],
    },
  ]

  const resiliationTableColumnsForReport = [
    {
      text: 'Huissier',
      value: 'huissier',
      align: 'center',
      url: 'paiement_huissier',
      comment_column: 'paiement_huissier',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que l\'huissier a été fait ?',
      fields: [
        {
          type: 'date', label: 'Huissier', value: 'huissier', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'huissier',
          text: 'Huissier',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'huissier',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_huissier',
          column: 'huissier',
        },
      ],
    },
    {
      text: 'Fin de dossier',
      value: 'end_of_file',
      align: 'center',
      url: 'paiement_end_of_file',
      comment_column: 'paiement_end_of_file',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que ce la fin de dossier?',
      fields: [
        {
          type: 'date',
          label: 'Fin de dossier',
          value: 'end_of_file',
          required: false,
          noLimit: true,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'end_of_file',
          text: 'Fin de dossier',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'end_of_file',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_end_of_file',
          column: 'end_of_file',
        },
      ],
    },
    {
      text: 'Résiliation',
      value: 'termination',
      align: 'center',
      url: 'resiliation_termination',
      comment_column: 'resiliation_termination',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la résiliation a été faite ?',
      fields: [
        {
          type: 'date', label: 'Résiliation', value: 'termination', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'termination',
          text: 'Résiliation',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'termination',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_termination',
          column: 'termination',
        },
      ],
    },
    {
      text: 'Résiliation BOD',
      value: 'termination_bod',
      align: 'center',
      url: 'resiliation_termination_bod',
      comment_column: 'resiliation_termination_bod',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la résiliation BOD a été faite ?',
      fields: [
        {
          type: 'date', label: 'Résiliation BOD', value: 'termination_bod', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'termination_bod',
          text: 'Résiliation BOD',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'termination_bod',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_termination_bod',
          column: 'termination_bod',
        },
      ],
    },
    {
      text: 'Résiliation Primento',
      value: 'termination_primento',
      align: 'center',
      url: 'resiliation_termination_primento',
      comment_column: 'resiliation_termination_primento',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la résiliation Primento a été faite ?',
      fields: [
        {
          type: 'date',
          label: 'Résiliation Primento',
          value: 'termination_primento',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'termination_primento',
          text: 'Résiliation Primento',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'termination_primento',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_termination_primento',
          column: 'termination_primento',
        },
      ],
    },
    {
      text: 'Mail auteur',
      value: 'author_mail',
      align: 'center',
      url: 'resiliation_author_mail',
      comment_column: 'resiliation_author_mail',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la résiliation Primento a été faite ?',
      fields: [
        {
          type: 'date', label: 'Mail auteur', value: 'author_mail', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'author_mail',
          text: 'Mail auteur',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'author_mail',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_author_mail',
          column: 'author_mail',
        },
      ],
    },
    {
      text: 'Vérif suppression',
      value: 'verification_deletion',
      align: 'center',
      url: 'resiliation_verification_deletion',
      comment_column: 'resiliation_verification_deletion',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que la vérif suppression a été faite ?',
      fields: [
        {
          type: 'date', label: 'Vérif suppression', value: 'verification_deletion', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'verification_deletion',
          text: 'Vérif suppression',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'verification_deletion',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_verification_deletion',
          column: 'verification_deletion',
        },
      ],
    },
    {
      text: 'Mail auteur J +15',
      value: 'author_mail_d_15',
      align: 'center',
      url: 'resiliation_author_mail_d_15',
      comment_column: 'resiliation_author_mail_d_15',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le Mail auteur J +15 a été envoyé?',
      fields: [
        {
          type: 'date', label: 'Mail auteur J +15', value: 'author_mail_d_15', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'author_mail_d_15',
          text: 'Mail auteur J +15',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'author_mail_d_15',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_author_mail_d_15',
          column: 'author_mail_d_15',
        },
      ],
    },
    {
      text: 'Fin de contrat',
      value: 'end_contract',
      align: 'center',
      url: 'resiliation_end_contract',
      comment_column: 'resiliation_end_contract',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: '',
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'end_contract',
          text: 'Fin de contrat',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'end_contract',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_end_contract',
          column: 'end_contract',
        },
      ],
    },
  ]
  const resiliationTableColumns = [
    ...resiliationTableColumnsForReport,
  ]

  const paymentTableColumnsForReport = [
    {
      text: 'Paiement auteur',
      value: 'author_payment',
      align: 'center',
      url: 'paiement_author_payment',
      comment_column: 'paiement_author_payment',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Confirmez-vous que le paiement auteur a été reçu ?',
      fields: [
        {
          type: 'date', label: 'Paiement auteur', value: 'author_payment', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      cellClass: 'text-center',
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'author_payment',
          text: 'Paiement auteur',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'author_payment',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_author_payment',
          column: 'author_payment',
        },
      ],
    },
    {
      text: 'Conditions paiement',
      value: 'payment_conditions',
      align: 'center',
      url: 'paiement_payment_conditions',
      comment_column: 'paiement_payment_conditions',
      blockBg: 'bg-grey',
      type: 'text',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      info: 'Veuillez indiquer la condition des paiements ci-dessous',
      fields: [
        {
          type: 'select',
          label: 'Conditions paiement',
          value: 'payment_conditions',
          required: false,
          item_value: 'id',
          item_text: 'name',
          items: paymentMethods.value,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      cellClass: 'text-center',
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        values: paymentMethods.value,
        menu: false,
        range: false,
        multiple: true,
      },
      filters: [
        {
          key: 'payment_conditions',
          text: 'Conditions paiement',
          place_holder: '',
          type: 'select',
          id: 'id',
          label: 'name',
          values: paymentMethods.value,
          menu: false,
          range: false,
          multiple: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'payment_conditions',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_payment_conditions',
          column: 'payment_conditions',
        },
      ],
    },

    {
      text: 'Cde exempl. auteur',
      value: 'author_copy_order',
      align: 'center',
      url: 'paiement_author_copy_order',
      comment_column: 'paiement_author_copy_order',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      hideValue: false,
      group: true,
      info: 'Confirmez-vous que le commande exempl. auteur a été faite ?',
      fields: [
        {
          type: 'date', label: 'Cde exempl. auteur', value: 'author_copy_order', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      cellClass: 'text-center',
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'author_copy_order',
          text: 'Cde exempl. auteur',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',
          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'author_copy_order',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_author_copy_order',
          column: 'author_copy_order',
        },
      ],
    },

    {
      text: 'Cde ex BNF',
      value: 'order_copies_bnf',
      align: 'center',
      url: 'publication_order_copies_bnf',
      comment_column: 'publication_order_copies_bnf',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: true,
      group: true,
      hideValue: false,
      info: '\'Confirmez-vous que le Cde exemplaires BNF a été fait ?',
      fields: [
        {
          type: 'date', label: 'Cde ex BNF', value: 'order_copies_bnf', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {
          if (!isPublicationbod(td)) {
            td.denieds = td.denieds || {}
            td.denieds[th.value] = true
            td.classes = td.classes || {}
            td.classes[th.value] = { 'w-100': true, 'h-100': true/* 'add-padding': true */ }
            td.classes[th.value][th.blockBg || 'bg-grey'] = true
          }
        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'order_copies_bnf',
          text: 'Cde ex BNF',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'order_copies_bnf',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_order_copies_bnf',
          column: 'order_copies_bnf',
        },
      ],
    },
  ]
  const paymentTableColumns = [
    ...tableCommonColumns,
    {
      text: 'Publication BOD',
      value: 'publishing_bod',
      align: 'center',
      url: 'publication_publishing_bod',
      comment_column: 'publication_publishing_bod',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      hideValue: false,
      info: 'Confirmez-vous que la publication BOD a été faite ?',
      fields: [
        {
          type: 'date', label: 'Publication BOD', value: 'publishing_bod', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'publishing_bod',
          text: 'Publication BOD',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'publishing_bod',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publishing_bod',
          column: 'publishing_bod',
        },
      ],
    },
    {
      text: 'Publication Laballery',
      value: 'publication_laballery',
      align: 'center',
      url: 'publication_publication_laballery',
      comment_column: 'publication_publication_laballery',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'publication_colors',
      comments: 'publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      hideValue: false,
      info: 'Confirmez-vous que la publication Laballery a été faite ?',
      fields: [
        {
          type: 'date',
          label: 'Publication Laballery',
          value: 'publication_laballery',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      functions: [
        (th, td) => {

        },
      ],
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
      },
      filters: [
        {
          key: 'publication_laballery',
          text: 'Publication Laballery',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'publication_laballery',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publication_laballery',
          column: 'publication_laballery',
        },
      ],
    },
    ...paymentTableColumnsForReport,
  ]

  const tableHead = ref({
    headers: textTableColumns,
    filters: {},
  })
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const isPaiementAuteurActive = ref(false)
  const isFormAttributionActive = ref(false)
  const deleteDialog = ref(false)
  const signatureDialog = ref(false)
  const isbnDialog = ref(false)
  const titleDialog = ref(false)
  const accordDialog = ref(false)
  const downloadObject = ref({ url: null, loading: false })
  const searchQuery = ref('')
  const authorFilter = ref(router.currentRoute.params.id)
  const payFilter = ref(null)
  const sourceFilter = ref(null)
  const statusFilter = ref(null)
  const stateFilter = ref(null)
  const tabFilter = ref(null)
  const totalItems = ref(0)
  const loading = ref(false)
  const showResiliation = ref(false)
  const showCommunication = ref(false)

  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const filters = ref([])
  const publicationStates = ref({
    CREATED_STATE: 0,
    SEND_STATE: 1,
    RESEND_STATE: 2,
    SIGNED_STATE: 3,
    COLLECTED_STATE: 4,
    TEXT_STATE: 5,
    COVER_STATE: 6,
    SUPERVISOR_STATE: 7,
    PUBLICATION_STATE: 8,
    RESILIATION_STATE: 9,
    PAYMENT_STATE: 9,
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) {
      if (!val.action || (!val.action.includes('resiliation') && val.action !== 'paiement_end_of_file' && val.action !== 'paiement_huissier')) {
        return false
      }
    }

    if (val) {
      item.value = val
    } else {
      item.value = blankItem
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  const openDialogAttribution = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false
    item.value = val
    isFormAttributionActive.value = !isFormAttributionActive.value
  }

  const openSignatureDialog = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false
    item.value = val
    signatureDialog.value = !signatureDialog.value
  }

  const openAccordDialog = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false
    item.value = val
    accordDialog.value = !accordDialog.value
  }
  const openIsbnDialog = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false
    item.value = val
    isbnDialog.value = !isbnDialog.value
  }
  const openPaiementAuteurDialog = val => {
    /* if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false */
    item.value = val
    isPaiementAuteurActive.value = !isPaiementAuteurActive.value
  }
  const openTitleDialog = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false
    item.value = val

    /* item.value.manuscript = { title: null } */
    item.value.comment = item.value.title

    titleDialog.value = !titleDialog.value
  }

  // fetch data
  const fetchItems = (download, downloadType) => {
    accordDialog.value = false
    signatureDialog.value = false
    isFormActive.value = false
    isFormAttributionActive.value = false
    isPaiementAuteurActive.value = false
    if (download) {
      downloadObject.value = { url: null, loading: true }
    }

    const singleFilters = filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({
      ...ele.filter,
      key: ele.value,

      /* type: ele.filter.type,
      value: ele.filter.value,
      column: ele.filter.column,
      join_table: ele.filter.join_table,
      join_column: ele.filter.join_column,
      local_column: ele.filter.local_column,
      multiple: ele.filter.multiple,
      month: ele.filter.month,
      columns: ele.filter.columns,
      conditions: ele.filter.conditions,
      is_condition: ele.filter.is_condition,
      key: ele.filter.value, */
      values: null,
      text: null,
      place_holder: null,
      label: null,
    }))

    // const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0).map(th => th.filters.map(ele => ({ ...ele.filter, key: ele.value, values: null })))
    const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0)
    const selectedMultipleFilters = []
    for (let i = 0; i < multipleFilters.length; i++) {
      selectedMultipleFilters.push(...multipleFilters[i].filters
        .filter(ele => ele.value !== '' && ele.value != null && (ele.value.constructor !== Array || ((ele.type === 'range' && ele.value && ele.value.length > 1 && ele.value[1] > 0) || (ele.type !== 'range' && ele.value.length > 0))))
        .map(ele => ({
          ...ele,
          key: ele.key,

          /* type: ele.type,
          value: ele.value,
          column: ele.column,
          join_table: ele.join_table,
          join_column: ele.join_column,
          local_column: ele.local_column,
          multiple: ele.multiple,
          month: ele.month,
          columns: ele.columns,
          conditions: ele.conditions,
          is_condition: ele.is_condition, */
          values: null,
          text: null,
          place_holder: null,
          label: null,
          /* , type: null */ })))
    }

    const columns = filters.value.filter(ele => (ele.filter != null || ele.filters != null) && !ele.value.includes('.') && !ele.queryExcluded).map(ele => ele.queryColumn || ele.value)
    columns.push('author_id')
    columns.push('genre_id')
    columns.push('text_assistant')
    columns.push('cover_assistant')
    columns.push('publication_assistant')
    columns.push('bat_cover')
    columns.push('text_bat')
    columns.push('manager_verify')
    columns.push('state')
    columns.push('author_payment')
    columns.push('publishing_bod')
    columns.push('bnf_form')
    columns.push('end_of_file')
    columns.push('termination')

    const records_types = filters.value.filter(ele => (ele.filter != null || ele.filters != null) && !ele.value.includes('.') && !ele.queryExcluded).map(ele => ele.comment_column || ele.value)
    const colors_names = filters.value.filter(ele => (ele.filter != null || ele.filters != null) && !ele.value.includes('.') && !ele.queryExcluded).map(ele => ele.value)
    colors_names.push('bat_cover')
    colors_names.push('text_bat')
    colors_names.push('manager_verify')
    colors_names.push('author_payment')
    colors_names.push('publishing_bod')
    records_types.push('publication_publishing_bod')

    // const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0).map(th => th.filters.map(ele => ({ ...ele.filter, key: ele.value, values: null })))
    store
      .dispatch('app-publication/fetchItems', {
        filters: [...singleFilters, ...selectedMultipleFilters].map(item => Object.fromEntries(Object.entries(item).filter(([key, value]) => !['values','text','label','place_holder'].includes(key)))),

        /* ...filters.value, */
        term: searchQuery.value,
        state: stateFilter.value,
        status: statusFilter.value,
        tab: tabFilter.value,
        pay: payFilter.value,
        author: authorFilter.value,
        source: sourceFilter.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        download,
        downloadType,
        columns,
        records_types,
        colors_names,
        showResiliation,
        showCommunication,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        if (download) {
          // remove loading state
          loading.value = false
          downloadObject.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const { data, total } = response.data
          items.value = data.map(ele => {
            if (ele.dos) {
              ele.dos = (ele.dos || 0).toFixed(2)
            }
            ele.price = (ele.price || 0).toFixed(2)
            ele.price_usd = (ele.price_usd || 0).toFixed(2)
            ele.amount = (ele.amount || 0).toFixed(2)
            ele.publication_records = ele.publication_records || []
            ele.publication_colors = ele.publication_colors || []

            return ele
          })
          totalItems.value = total
          const ids = items.value.map(ele => ele.id)

          /* axios.get('/publication_records', { params: { publications: ids, types: columns_names } }).then(response => {

            if (response.data && response.data) {
              for (var key in response.data) {
                const ind = items.value.findIndex(ele => ele.id === parseInt(key))

                if(ind >= 0){
                  items.value[ind].publication_records = response.data[key]
                }

              }
            }
          }) */

          /* axios.get('/publication_colors', { params: { publications: ids, types: colors_columns_names } }).then(response => {

            if (response.data && response.data) {
              for (var key in response.data) {
                const ind = items.value.findIndex(ele => ele.id === parseInt(key))

                if(ind >= 0){
                  items.value[ind].publication_colors = response.data[key]
                }

              }
            }
            loading.value = false
          }) */
        }

        // remove loading state
        loading.value = false

        if (item.value.action === 'collecte_word_file') {
          /* console.log('word_file')
          openDialogAttribution({
            ...item.value, publication_id: item.value.id, gestion_id: null, assistant_id: null, task_date: null,
          }) */
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const canVerify = val => {
    if (val.bat_cover && val.text_bat) {
      if (val.publication_records && val.publication_records.length > 0) {
        const recordsT = val.publication_records.filter(ele => ele.type && ele.type.includes('text_text_bat')).sort((a, b) => b.id - a.id)
        if (recordsT.length === 0 || !recordsT[0].color || !recordsT[0].color.id || recordsT[0].color_id != store.state.app.settings.publication_bat_status) {
          return false
        }

        const records = val.publication_records.filter(ele => ele.type && ele.type.includes('bat_cover')).sort((a, b) => b.id - a.id)
        if (records.length === 0 || !records[0].color || !records[0].color.id || records[0].color_id != store.state.app.settings.publication_bat_status) {
          return false
        }

        return true
      }

      return false
    }

    return false
  }
  const isPublicationbod = val => {
    if (val.publishing_bod) {
      if (val.publication_colors && val.publication_colors.length > 0) {
        const recordsC = val.publication_colors.find(ele => ele.colomn_name && ele.colomn_name === 'publishing_bod')
        if (!recordsC || !recordsC.color || recordsC.color.id !== (store.state.app.settings.publication_bod_status || 1)) {
          return false
        }

        // const recordsT = val.publication_records.filter(ele => ele.type && ele.type.includes('publishing_bod')).sort((a, b) => b.id - a.id)
        // if (recordsT.length === 0 || !recordsT[0].color || !recordsT[0].color.id || recordsT[0].color_id != (store.state.app.settings.publication_bod_status || 1)) {
        //   return false
        // }

        return true
      }

      return false
    }

    return false
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-publication/deleteItem', item.value.id)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          loading.value = false
        })
    }
  }
  const attributeIsbn = () => {
    if (item.value) {
      loading.value = true
      const payload = { id: item.value.id }

      axios.post(`/publications/action/publication_isbn/${item.value.id}`,
        payload)
        .then(response => {
          loading.value = false
          isbnDialog.value = false
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            } else {
              isSnackbarBottomVisible.value = true
              snackBarMessage.value = response.data.message

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return true
          }

          // emit('update:is-form-active', false)
          fetchItems()
        }).catch(error => {
          loading.value = false
          isbnDialog.value = false
        })
    }
  }
  const changeTitle = () => {
    if (item.value) {
      loading.value = true
      const payload = {
        id: item.value.id,
        title: item.value.title,
        comment: item.value.comment,
      }

      axios.post(`/publications/action/publication_title/${item.value.id}`,
        payload)
        .then(response => {
          loading.value = false
          titleDialog.value = false
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            } else {
              isSnackbarBottomVisible.value = true
              snackBarMessage.value = response.data.message

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return true
          }

          // emit('update:is-form-active', false)
          fetchItems()
        }).catch(error => {
          loading.value = false
          titleDialog.value = false
        })
    }
  }
  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      if (!loading.value) {
        loading.value = true
        selectedRows.value = []
        fetchItems()
      }
    }, 1500)
  }, { deep: true })
  watch([searchQuery, payFilter, sourceFilter, statusFilter, options], () => {
    if (!loading.value) {
      loading.value = true
      selectedRows.value = []
      fetchItems()
    }
  }, { deep: true })

  const select = data => {
    if (data.state) {
      selectedRows.value.push(data.item)
    } else {
      selectedRows.value.splice(selectedRows.value.findIndex(ele => ele.id === data.item.id), 1)
    }
  }

  const saved = data => {
    items.value[data.index] = data.item
  }

  return {
    saved,
    select,
    publicationStates,
    item,
    signatureForm,
    items,
    paymentMethods,
    blockeds,
    collectTableColumns,
    downloadObject,
    textTableColumns,
    coverTableColumns,
    supervisionTableColumns,
    publicationTableColumns,
    tableCommonColumns,
    paymentTableColumns,
    resiliationTableColumns,
    tableColumns: authorFilter.value != null ? tableColumnsAuthorView : tableColumns,
    signatureTableColumns,
    searchQuery,
    tableHead,
    filters,
    payFilter,
    sourceFilter,
    statusFilter,
    stateFilter,
    tabFilter,
    totalItems,
    loading,
    options,
    selectedRows,
    isFormActive,
    isFormAttributionActive,
    isPaiementAuteurActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    signatureDialog,
    isbnDialog,
    titleDialog,
    accordDialog,
    openSignatureDialog,
    openAccordDialog,
    resetForm,
    openDialog,
    openDialogAttribution,
    openPaiementAuteurDialog,
    openIsbnDialog,
    openTitleDialog,
    deleteItem,
    canVerify,
    isPublicationbod,
    deleteDialog,
    confirmDelete,
    fetchItems,
    tableHeight,
    attributeIsbn,
    changeTitle,
    tableCommonColumnsReport,
    paymentTableColumnsForReport,
    resiliationTableColumnsForReport,
    publicationTableColumnsForReport,
    supervisionTableColumnsForReport,
    collectTableColumnsForReport,
    textTableColumnsForReport,
    coverTableColumnsForReport,
    signatureTableColumnsForReport,
    showResiliation,
    showCommunication,
    windowHeight,
  }
}
